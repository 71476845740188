<template>
  <div class="w-full bg-black p-4 rounded mdmax:p-2 mt-4">
    <div>
      <div class="w-full">
        <pdf ref="pdf" :src="src" :rotate="0" :page="page" @num-pages="numPages = $event" @page-loaded="onLoaded"></pdf>
      </div>
      <div class="my-2 flex justify-between gap-4 items-center">
        <div class="relative bg-neutral-50 w-full h-2 rounded-lg z-0">
          <div class="absolute left-0 h-2 bg-yellow rounded-lg" :style="{ width: `${readingProgress}%` }"></div>
        </div>
      </div>
      <transition name="fade" mode="in-out">
        <div class="flex justify-center gap-4 items-center my-4 relative h-7">
          <div class="absolute right-0 top-1/2 transform -translate-y-1/2 text-xs font-medium" v-if="documented?.downloadStatus">
            <Button buttonText="Download" @action="downloadFile(documented?.documentUrl)" type="primary" size="small" />
          </div>
          <div v-if="numPages > 1" class="grid grid-flow-col gap-4">
            <Button buttonText="Prev" @action="goPrev()" type="primary" size="small" :disabled="page === 1" />
            <Button buttonText="Next" @action="nextPrev()" type="primary" size="small" :disabled="numPages === page" />
          </div>
          <div class="absolute left-0 top-1/2 transform -translate-y-1/2 text-white text-xs font-medium">
            Page: <strong>{{ page }}</strong> of {{ numPages }}
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
<script>
import pdf from 'vue-pdf'

export default {
  components: {
    pdf,
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button')
  },
  props: {
    src: {
      type: String,
      default: ''
    },
    documented: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      show: true,
      page: 1,
      numPages: 0,
      isLoaded: false
    }
  },
  mounted() {
    this.triggerAction('READ')
  },
  computed: {
    readingProgress() {
      const progress = Math.ceil((this.page / this.numPages) * 100)
      return progress
    },
    pageLoaded() {
      return this.isLoaded
    }
  },
  methods: {
    onLoaded(event) {
      if (this.numPages === 1) {
        this.triggerAction('FINISH')
      }
    },
    triggerPageLoaded() {
      this.isLoaded = true
    },
    goPrev() {
      this.page--
    },
    nextPrev() {
      this.page++
      if (this.page === this.numPages) {
        this.triggerAction('FINISH')
      }
    },
    downloadFile(fileURL) {
      if (!window.ActiveXObject) {
        var save = document.createElement('a')
        save.href = fileURL
        save.target = '_blank'
        var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1)
        save.download = 'Materi' || filename
        if (navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) && navigator.userAgent.search('Chrome') < 0) {
          document.location = save.href
          // window event not working here
        } else {
          var evt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: false
          })
          save.dispatchEvent(evt)
          ;(window.URL || window.webkitURL).revokeObjectURL(save.href)
        }
      } else if (!!window.ActiveXObject && document.execCommand) {
        var _window = window.open(fileURL, '_blank')
        _window.document.close()
        _window.document.execCommand('SaveAs', true, 'Materi' || fileURL)
        _window.close()
      }
    },
    triggerAction(action) {
      this.$emit('action', action)
    }
  }
}
</script>
